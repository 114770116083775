.menuContainer {
  --size-button: 160px;
  --size-header: var(--size-button);
}

.menuContainer .menu.stuck {
  --size-header: calc(0.5 * var(--size-button));
}

.menu {
  font-size: var(--font-size-M);
  position: sticky;
  top: calc(-1px - 1em);
  z-index: 9;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0;
  background-color: #fff;
  height: var(--size-button);
  border: none;
  overflow: hidden;
}

@media (max-width: 1200px) {
  .menuContainer {
    --size-button: 120px;
  }
}

@media (max-width: 1000px) {
  .menuContainer {
    --size-button: 100px;
  }
}

@media (max-width: 800px) {
  .menuContainer {
    --size-button: 80px;
  }
}

.menu.stuck {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: var(--size-header);
  max-height: var(--size-header);
  overflow: hidden;
  z-index: 999;
  pointer-events: all;
}

.menuContainer .menu > button {
  height: var(--size-header);
  font-size: var(--font-size-M);
}

.menu > button.logo {
  background-color: var(--color-hero-background);
  font-size: calc(0.25 * var(--size-button));
  padding: 0.75rem 1rem;
  line-height: 0.8;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  cursor: pointer;
  font-family: var(--font-serif);
  width: 100%;
  transition: none;
}

.menu > button.logo.selected {
  background: var(--color-text);
  color: #fffe;
  border: 1px solid var(--color-text);
}

.menu.stuck > button.logo {
  font-size: calc(0.45 * var(--size-header));
  align-items: center;
  padding: 0 0.5em;
}

.menu.stuck > button.logo > * {
  margin: 0;
}

.menu > button.logo > div {
  text-align: right;
  max-width: 7em;
}

.menu > div.items {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.menu button {
  border: 1px solid var(--color-border);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem;
  margin: 0 0 0 -1px;
  color: var(--color-text);
  background-color: #fff;
  cursor: pointer;
  transition: all 0.2s;
  width: var(--size-button);
  height: var(--size-button);
  box-sizing: border-box;
  box-shadow: 0 0 currentColor inset;
}

.menu.stuck div.hamburger > button {
  flex-direction: row;
  align-items: center;
}

.menu.stuck button {
  height: var(--size-header);
  line-height: 1;
}

.menu.stuck button > svg {
  font-size: 0;
}

.menu button.selected {
  color: #fff;
  background-color: var(--color-text);
  pointer-events: none;
  cursor: default;
  border: 1px solid var(--color-text);
}

.menu > div > button:hover {
  box-shadow: 0 -0.5rem currentColor inset;
  padding-bottom: 1rem;
}

.menu > div > button.loginButton:hover {
  box-shadow: 0 -0.5rem #fff7 inset;
  color: #fffe;
  background-color: var(--color-text);
  transition: all 0.6s;
}

.menu > div > button > svg {
  place-self: end;
}

.menu > div.hamburger {
  display: none;
}

@media (max-width: 480px) {
  .menu > button.logo {
    font-size: 2rem;
    padding: 12px;
  }

  .menu.stuck > button.logo {
    font-size: 1.5rem;
    padding: 0.5em;
  }

  .menu.stuck .hamburger > button {
    display: grid;
    place-items: center;
    width: 4em;
  }

  .menu.stuck .hamburger > button > svg {
    font-size: calc(0.5 * var(--size-header));
    place-self: center;
  }

  .menu.stuck .hamburger > button > div {
    display: none;
  }
}

@media (max-width: 800px) {
  .menu > div.items {
    display: none;
  }
  .menu > div.hamburger {
    display: block;
  }
}
