.nextPanel {
  color: #bbb;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2em 0;
  gap: 1em;
  height: 240px;
  margin-left: var(--margin);
}

.nextPanel > div {
  text-align: center;
}
