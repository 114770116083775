.milestones .step {
  display: grid;
  --size: 256px;
  grid-template-columns: var(--margin) var(--size) 1fr;
  gap: 24px;
  max-height: var(--size);
  padding: 0;
  margin: 0;
}

.step img {
  height: var(--size);
  grid-column: 2/3;
}

.step > div {
  max-width: 640px;
  display: grid;
  grid-template-rows: auto 1fr auto;
  place-items: start;
}

.step > div > h2 {
  font-family: var(--font-serif);
  font-size: var(--Sizes-Font-7xl, 60px);
}

.milestones > header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  flex-wrap: nowrap;
  margin-bottom: 2em;
}

.milestones > header > button {
  margin: 0;
  position: 0;
  cursor: pointer;
}

.milestones > header > button[disabled] {
  opacity: 0.5;
  filter: grayscale(1);
}

.milestones > header > button > svg {
  flex: 0 0 auto;
}

.milestones > header > div {
  flex: 1 1 auto;
}
