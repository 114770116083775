.verticalRuler {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  margin: 15px 0;
}

.verticalRuler > div {
  display: block;
  width: 2px;
  height: 100%;
  font-size: 0;
  content: '.';
  background-color: var(--color-hero-background, currentColor);
}
