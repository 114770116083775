.termsAndConditions p {
  max-width: 1000px;
  margin-top: 1em;
  margin-bottom: 1em;
}

.termsAndConditions ol {
  margin-left: var(--margin);
}

.termsAndConditions ol > li {
  list-style: none;
  margin-top: 3em;
  max-width: 900px;
}

.termsAndConditions ol > li > h2 {
  font-size: 150%;
  font-weight: bolder;
  margin-top: -0.5em;
}

.termsAndConditions ol > li ul {
  margin-left: 1em;
}

.termsAndConditions ol > li ul > li {
  list-style: disc;
}
