@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Text:ital@0;1&family=Gabarito:wght@400..900&display=swap');

.LandingPage_landingPage__TkmBR {
  --color-hero-background: #f0f0f0;
  --color-border: var(--Colors-Gray-4, #bfbfbf);
  --color-text: var(--Colors-Blue-8, #003a8c);
  --font-serif: 'DM Serif Text', serif;
  --font-sans-serif: 'Gabarito', sans-serif;
  --font-size-M: 19px;
  --font-size-L: calc(1.5 * var(--font-size-M));
  --font-size-XL: calc(2 * var(--font-size-M));

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --margin: 20vw;

  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 1em;
  color: var(--color-text);
  background-color: #fff;
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: 'Gabarito', serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
  text-wrap: balance;
  font-size: var(--font-size-M);
  scroll-behavior: smooth;
}

.LandingPage_landingPage__TkmBR a,
.LandingPage_landingPage__TkmBR button {
  all: unset;
  cursor: pointer;
  font-size: var(--font-size-M);
  box-sizing: border-box;
}

.LandingPage_landingPage__TkmBR .font-gabarito {
  font-family: var(--font-sans-serif);
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
}

.LandingPage_landingPage__TkmBR .font-dm {
  font-family: var(--font-serif);
  font-weight: 400;
  font-style: normal;
}

.LandingPage_landingPage__TkmBR .font-dm-italic {
  font-family: var(--font-serif);
  font-weight: 400;
  font-style: italic;
}

.LandingPage_landingPage__TkmBR h1 {
  font-size: var(--font-size-XL);
  padding-left: var(--margin);
  margin: 1em 0;
  box-sizing: content-box;
  max-width: 18em;
  line-height: 1;
}

.LandingPage_landingPage__TkmBR h1::after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: calc(var(--margin) - 0.5em);
  height: 2px;
  background-color: var(--color-hero-background, #000);
}

.LandingPage_landingPage__TkmBR h1 > big {
  display: block;
  font-weight: bolder;
  margin: 0;
}

.LandingPage_landingPage__TkmBR h2 {
  font-size: var(--font-size-L);
}

.LandingPage_landingPage__TkmBR h3 {
  font-size: var(--font-size-M);
}

.LandingPage_landingPage__TkmBR h1,
.LandingPage_landingPage__TkmBR h2,
.LandingPage_landingPage__TkmBR h3 {
  text-wrap: balance;
  position: relative;
  font-family: var(--font-serif);
}

.LandingPage_landingPage__TkmBR p {
  font-size: var(--font-size-M);
  margin-left: var(--margin);
  max-width: min(100%, min(1000px, calc(100vw - 2 * var(--margin))));
  -webkit-hyphens: auto;
          hyphens: auto;
  text-align: justify;
  line-height: 1.2;
}
.LandingPage_landingPage__TkmBR ul {
  max-width: min(1000px, calc(100vw - 2 * var(--margin)));
}

@media (max-width: 640px) {
  .LandingPage_landingPage__TkmBR {
    --margin: 48px;
    --font-size-M: 16px;
  }
  .LandingPage_landingPage__TkmBR h1:after {
    display: none;
  }
}

@media (max-width: 480px) {
  .LandingPage_landingPage__TkmBR {
    --margin: 0;
    --font-size-M: 13px;
    padding: 0 4px;
  }
}

button.styles_hoverableButton__EpLLS,
a.styles_hoverableButton__EpLLS,
.styles_hoverableButton__EpLLS {
  background: #fff;
  color: var(--color-text);
  transition: all 0.5s;
}

button.styles_hoverableButton__EpLLS:hover,
a.styles_hoverableButton__EpLLS:hover,
.styles_hoverableButton__EpLLS:hover,
button.styles_hoverableButton__EpLLS:active,
a.styles_hoverableButton__EpLLS:active,
.styles_hoverableButton__EpLLS:active {
  color: #fff;
  background: var(--color-text);
}

.Button_button___cibm {
  display: flex;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.Button_button___cibm.Button_end__8d3Sy {
  justify-content: flex-end;
}

.Button_button___cibm.Button_center__80FY2 {
  justify-content: flex-center;
}

.Button_button___cibm.Button_start__9bLgi {
  justify-content: flex-start;
}

div.Button_core__gh6xQ {
  margin: 0;
  padding: 16px 20px;
  border: 1px solid var(--color-border);
  width: 365px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  max-width: calc(100vw - 3rem);
}

div.Button_core__gh6xQ > div:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.25rem;
  margin: 0;
  padding: 0;
}

div.Button_core__gh6xQ > div > * {
  margin: 0;
  padding: 0;
}

div.Button_core__gh6xQ > div:last-child {
  line-height: 125%;
  font-size: 2.25rem;
  margin: 0;
  padding: 0;
  leading-trim: both;
  text-edge: cap;
}

@media (max-width: 640px) {
  div.Button_core__gh6xQ {
    width: 320px;
    padding: 12px 16px;
  }
  div.Button_core__gh6xQ > div:first-child {
    font-size: 1.15rem;
  }
  div.Button_core__gh6xQ > div:last-child {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  div.Button_core__gh6xQ {
    width: 240px;
    padding: 8px 12px;
  }
  div.Button_core__gh6xQ > div:first-child {
    font-size: 1rem;
  }
  div.Button_core__gh6xQ > div:last-child {
    font-size: 1.5rem;
  }
}

.VerticalRuler_verticalRuler__Amtw5 {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  margin: 15px 0;
}

.VerticalRuler_verticalRuler__Amtw5 > div {
  display: block;
  width: 2px;
  height: 100%;
  font-size: 0;
  content: '.';
  background-color: var(--color-hero-background, currentColor);
}

.NextPanel_nextPanel__MPnWz {
  color: #bbb;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2em 0;
  gap: 1em;
  height: 240px;
  margin-left: var(--margin);
}

.NextPanel_nextPanel__MPnWz > div {
  text-align: center;
}

.Hero_hero__4dzlS {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0;

  --color-bg: #f0f0f0;
  --gradient-angle: 150deg;
}

.Hero_splash__9d_ew.Hero_image__nJqfK {
  --color-bg: var(--color-text);
}

.Hero_splash__9d_ew {
  height: calc(75vh - 100px);
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 0;
  margin: 0;
}

.Hero_splash__9d_ew > div:last-child {
  position: relative;
  border: 1px solid var(--color-border);
  border-top: none;
  background: var(--color-text);
}

.Hero_splash__9d_ew > div:last-child > video,
.Hero_splash__9d_ew > div:last-child > img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: top right;
     object-position: top right;
  z-index: 0;
}

.Hero_splash__9d_ew .Hero_text__kV_P_ {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(
    var(--gradient-angle),
    var(--color-bg) 29.85%,
    rgba(240, 240, 240, 0) 61.87%
  );
  z-index: 1;
  font-size: clamp(2rem, 5vw, 3rem);
  padding-left: 2em;
  text-wrap: balance;
}

.Hero_splash__9d_ew.Hero_image__nJqfK .Hero_text__kV_P_ {
  color: #fff;
  background: linear-gradient(
    var(--gradient-angle),
    var(--color-bg) 29.85%,
    rgba(240, 240, 240, 0) 61.87%
  );
}

.Hero_splash__9d_ew > div:last-child > div > * {
  /* max-width: 600px; */
  max-width: 80%;
}

.Hero_splash__9d_ew > div:last-child > div > div.Hero_content__rq_aO {
  font-size: 1.5rem;
}

h1.Hero_largeTitle__IdAJi {
  font-family: var(--font-serif);
  font-size: 4vw;
  line-height: 1.25;
  margin: 0;
  padding: 0;
}

h1.Hero_largeTitle__IdAJi::after {
  display: none;
}

@media (max-width: 800px) {
  .Hero_splash__9d_ew {
    grid-template-columns: 0px 1fr;
  }

  h1.Hero_largeTitle__IdAJi {
    font-size: 7.5vw;
    text-shadow:
      0 0 0.5em var(--color-bg),
      0 0 0.125em var(--color-bg);
  }

  .Hero_splash__9d_ew > div:last-child > div > div.Hero_content__rq_aO {
    font-size: 1.2rem;
  }
}

@media (min-width: 640px) {
  .Hero_showOnlyForMobile__LbMdd {
    display: none;
  }
}

.OurFoundations_ourFoundations__CJPdS {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 20vw;
  grid-auto-rows: auto;
  grid-template-areas:
    'S P'
    'L P';
  gap: 0;
  place-items: stretch;
  overflow-x: hidden;
  margin-left: var(--margin);
}

.OurFoundations_section__Q9bh6 {
  grid-area: S;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding: 1em;
  background-color: var(--color-hero-background);
}

.OurFoundations_picture__jdsxp {
  grid-area: P;
  position: relative;
  width: 100%;
  height: 100%;
}

.OurFoundations_picture__jdsxp > * {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.OurFoundations_section__Q9bh6 h2 {
  width: 100%;
  font-family: var(--font-serif);
  font-size: 4vw;
  line-height: 1.1;
  margin: 0;
  margin-bottom: 2em;
  max-width: none;
}

.OurFoundations_section__Q9bh6 h3 {
  font-size: 1.4em;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-family: var(--font-sans-serif);
}

.OurFoundations_section__Q9bh6 p {
  font-size: 1.4em;
  margin: 0;
  padding: 0;
}

.OurFoundations_ourFoundations__CJPdS > footer {
  grid-area: L;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  /* Important: this gap plus the outline in the buttons make the border to collapse properly. */
  gap: 1px;
  border: 1px solid var(--color-border);
}

.OurFoundations_ourFoundations__CJPdS > footer > .OurFoundations_link__uEkxB {
  width: 100%;
  border: none;
  outline: 1px solid var(--color-border);
}

.OurFoundations_ourFoundations__CJPdS > footer > .OurFoundations_link__uEkxB > div {
  width: 100%;
  max-width: none;
  margin: 0;
  border: none;
  outline: none;
}

@media (max-width: 1000px) {
  .OurFoundations_ourFoundations__CJPdS {
    grid-template-areas:
      'S S'
      'L P';
  }

  .OurFoundations_ourFoundations__CJPdS > footer {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

@media (max-width: 480px) {
  .OurFoundations_ourFoundations__CJPdS {
    grid-template-areas:
      'S'
      'P'
      'L';
    grid-template-columns: 1fr;
    grid-template-rows: auto 200px auto;
  }

  .OurFoundations_section__Q9bh6 h2 {
    font-size: 7vw;
  }

  .OurFoundations_section__Q9bh6 > div > img {
    display: none;
  }

  .OurFoundations_section__Q9bh6 > div {
    z-index: 1;
  }

  .OurFoundations_picture__jdsxp {
    width: 100%;
  }

  .OurFoundations_ourFoundations__CJPdS > footer > .OurFoundations_link__uEkxB {
    position: relative;
    justify-content: stretch;
    height: 5rem;
  }

  .OurFoundations_ourFoundations__CJPdS > footer > .OurFoundations_link__uEkxB > div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.ContributorsNavigation_contributorsPanel__2XO7G {
  --margin-letter: var(--custom-margin-letter, 12px);
  --size-letter: var(--custom-size-letter, 32px);

  max-width: min(740px, calc(100vw - 2px));
}

.ContributorsNavigation_contributorsPanel__2XO7G header {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.ContributorsNavigation_thanks__dUhEo {
  font-family: var(--font-serif);
  background-color: var(--color-hero-background);
  line-height: 0.8;
  font-size: var(--font-size-L);
  padding: 1.5rem;
  text-align: right;
}

.ContributorsNavigation_more__l7TrW {
  background-color: var(--color-text);
  color: #fff;
  padding: 1.5rem;
  font-size: var(--font-size-M);
}

.ContributorsNavigation_pages__52aKU {
  grid-column: 2/4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  padding: 0;
  padding-top: 1em;
  padding-bottom: 2em;
}

.ContributorsNavigation_chevron__y5eIS {
  font-size: 0.6rem;
}

.ContributorsNavigation_pages__52aKU div.ContributorsNavigation_container__m31pc {
  position: relative;
  width: 100%;
  height: calc(1.5 * var(--size-letter));
}

.ContributorsNavigation_pages__52aKU div.ContributorsNavigation_container__m31pc::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: calc(2 * var(--margin-letter));
  height: 100%;
  background: linear-gradient(to right, #fff, #fff0);
  z-index: 1;
}

.ContributorsNavigation_pages__52aKU div.ContributorsNavigation_container__m31pc::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: calc(2 * var(--margin-letter));
  height: 100%;
  background: linear-gradient(to left, #fff, #fff0);
  z-index: 1;
}

.ContributorsNavigation_pages__52aKU div.ContributorsNavigation_letters__o82q0 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  font-size: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0;
  flex-wrap: nowrap;
  padding: 0 var(--margin-letter);
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.ContributorsNavigation_pages__52aKU div.ContributorsNavigation_letters__o82q0::-webkit-scrollbar {
  display: none;
}

.ContributorsNavigation_pages__52aKU button {
  display: inline-block;
  cursor: pointer;
  border: 1px solid var(--color-border);
  display: grid;
  place-items: center;
  width: 1.5em;
  height: 1.5em;
  flex: 0 0 auto;
}

.ContributorsNavigation_pages__52aKU > button {
  border: none;
}

.ContributorsNavigation_pages__52aKU .ContributorsNavigation_letter__ZY_K7 {
  padding: 0 var(--margin-letter);
  z-index: 0;
}

.ContributorsNavigation_pages__52aKU .ContributorsNavigation_letter__ZY_K7 button {
  width: var(--size-letter, 32px);
  height: var(--size-letter, 32px);
  box-sizing: border-box;
  font-size: calc(0.5 * var(--size-letter, 32px));
}

.ContributorsNavigation_pages__52aKU .ContributorsNavigation_letter__ZY_K7 button[disabled] {
  cursor: default;
  pointer-events: none;
  background-color: var(--color-text);
  color: #fff;
}

@media (max-width: 720px) {
  .ContributorsNavigation_contributorsPanel__2XO7G header {
    grid-template-columns: 1fr;
  }

  .ContributorsNavigation_contributorsPanel__2XO7G .ContributorsNavigation_thanks__dUhEo {
    font-size: 2em;
  }
}

.CenteredColumn_centeredColumn__PSc4r {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 0;
}

.CenteredColumn_centeredColumn__PSc4r > div {
  grid-column: 2/3;
}

.ContributorsList_contributorsList__E7Y_D {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 4em 3.2em;
  padding: 0 4rem;
}

@media (max-width: 640px) {
  .ContributorsList_contributorsList__E7Y_D {
    padding: 0;
  }
}

.ContributorsList_contributorsList__E7Y_D > div {
  width: min(calc(100vw - 2px), 360px);
  max-height: calc(4 * var(--font-size-L));
}

.ContributorsList_contributorsList__E7Y_D > div > .ContributorsList_name__SdmdL {
  font-family: var(--font-serif);
  font-size: var(--font-size-L);
  line-height: 1;
  padding: 0.4em 0.5em;
  background-color: var(--color-hero-background);
}

.ContributorsList_contributorsList__E7Y_D > div > .ContributorsList_links__x_qPN {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ContributorsList_contributorsList__E7Y_D > div > .ContributorsList_links__x_qPN > a {
  font-size: var(--font-size-M);
}

.ContributorsList_links__x_qPN a {
  display: inline-block;
  cursor: pointer;
  font-size: 1rem;
  border: solid 1px var(--color-hero-background);
  padding: 6px 12px;
}

button.ContributorsList_loadMore__lYk5E {
  display: inline-block;
  padding: 0.5em 2em;
  cursor: pointer;
  border: 1px solid var(--color-border);
  border-radius: 99vmax;
  margin-top: 3rem;
}

.ContributorsPanel_contributorsPanel__O8oj_ {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: column;
  gap: 3rem;
}

button.card_card__pIneL {
  position: relative;
  border: 1px solid var(--color-hero-background);
  padding: 1em;
  margin: 0 -1px -1px 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
  width: var(--size-block, 256px);
  aspect-ratio: 1/1;
  overflow: hidden;
}

.card_comingSoon__CIAz2 {
  display: inline-block;
  padding: 0.25em 1em;
  color: #fffa;
  border: 1px solid currentColor;
  border-radius: 99vmax;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}

button.card_card__pIneL .card_content__exzmM {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  color: #fffe;
  z-index: 2;
}

.card_card__pIneL h2,
.card_card__pIneL h3 {
  margin: 0;
  color: #fffe;
}

.card_card__pIneL h2 {
  position: relative;
  top: -4px;
  font-family: var(--font-serif);
}

.card_card__pIneL h3 {
  font-weight: lighter;
}

.card_background__Se7hU {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.card_curtain__hTT4o {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(115deg, #fff 20%, #fff0);
}

.VirtualLabsPanel_triBlocks__vEH8T {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 0;
  --size-block: clamp(min(calc(100vw - 1rem), 400px), 30%, 600px);
  padding: 0 40px;
}

@media (max-width: 480px) {
  .VirtualLabsPanel_triBlocks__vEH8T {
    padding: 0;
  }
}

a.PortalCard_card__lpqgu {
  position: relative;
  border: 1px solid var(--color-border);
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 800px;
  height: 200px;
  max-width: calc(100vw - 8px);
  margin: 0;
  padding: 0;
  transition: all 0.6s;
}

a.PortalCard_card__lpqgu .PortalCard_content__j9em2 h2 {
  margin: 0;
}

a.PortalCard_card__lpqgu:hover {
  color: #fffe;
  background-color: var(--color-text);
}

a.PortalCard_card__lpqgu .PortalCard_content__j9em2 {
  width: 100%;
  transition: all 0.6s;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-image: linear-gradient(35deg, #fff, #fffd 60%, #fff0);
  z-index: 1;
}

a.PortalCard_card__lpqgu:hover .PortalCard_content__j9em2 {
  background: var(--color-text);
}

.PortalCard_content__j9em2 h2 {
  font-family: var(--font-serif);
}

a.PortalCard_card__lpqgu .PortalCard_pictureContainer__p4kEa {
  position: relative;
  flex: 0 0 auto;
  width: 200px;
  height: 100%;
  overflow: hidden;
}

a.PortalCard_card__lpqgu .PortalCard_picture__uGgC7 {
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.6s;
}

a.PortalCard_card__lpqgu:hover .PortalCard_picture__uGgC7 {
  transform: scale(1.2) rotate(10deg);
}

@media (max-width: 800px) {
  a.PortalCard_card__lpqgu .PortalCard_pictureContainer__p4kEa {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }

  a.PortalCard_card__lpqgu {
    height: 280px;
  }

  a.PortalCard_card__lpqgu .PortalCard_content__j9em2 {
    color: #fffe;
    background: color-mix(in srgb, var(--color-text), transparent);
  }
}

.PortalsPanel_portalsPanel__09PWB {
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 16px;
  max-width: 1800px;
}

.NewsCard_newsCard__gN8gK {
  position: relative;
  width: min(640px, calc(100vw - 8px));
  height: 240px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0;
  flex: 0 0 auto;
  scroll-snap-stop: always;
  scroll-snap-align: start;
}

.NewsCard_newsCard__gN8gK > div.NewsCard_content__TcAa0 {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding-right: 16px;
  z-index: 1;
}

.NewsCard_newsCard__gN8gK > div.NewsCard_content__TcAa0 > * {
  flex: 0 0 auto;
}

.NewsCard_newsCard__gN8gK > div.NewsCard_content__TcAa0 > small {
  flex: 1 1 auto;
  color: #0008;
  font-weight: normal;
}

.NewsCard_newsCard__gN8gK > div.NewsCard_content__TcAa0 > p {
  margin: 0;
}

.NewsCard_newsCard__gN8gK > div.NewsCard_picture__Emhdd {
  z-index: 0;
  width: 240px;
  flex: 0 0 auto;
  position: relative;
}

.NewsCard_newsCard__gN8gK > div.NewsCard_picture__Emhdd > * {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

@media (max-width: 600px) {
  .NewsCard_newsCard__gN8gK > div.NewsCard_picture__Emhdd {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .NewsCard_newsCard__gN8gK > div.NewsCard_content__TcAa0 {
    padding: 1em;
    background-color: color-mix(in srgb, var(--color-text) 66%, transparent);
    color: #fffe;
  }

  .NewsCard_newsCard__gN8gK > div.NewsCard_content__TcAa0 > small {
    color: #fffc;
  }
}

.News_news__O1kbg > main {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 48px;
  flex-wrap: nowrap;
  scroll-behavior: smooth;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  padding-bottom: 24px;
}

.News_news__O1kbg > header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.News_news__O1kbg > header > * {
  flex: 1 1 auto;
}

.News_news__O1kbg > header > div {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px;
}

.News_news__O1kbg > header > div > button.News_page__inlta {
  content: '';
  width: 24px;
  height: 6px;
  border-radius: 99vmax;
  background-color: #0004;
}

.News_news__O1kbg > header > div > button.News_page__inlta.News_selected__u2GVz {
  background-color: var(--color-text);
}

.News_news__O1kbg > header > div > button.News_arrow__tFLGG {
  width: 3em;
  display: grid;
  place-items: center;
}


.SectionMain_sectionMain__BJDXr {
}

.FooterPanel_footerPanel__0fm8A {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.FooterPanel_title__ESVC2 {
  width: 50%;
  padding: 1rem;
  background-color: var(--color-hero-background);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.FooterPanel_title__ESVC2 > h2 {
  text-align: right;
  line-height: 0.8;
}

.FooterPanel_title__ESVC2 > div:first-child {
  font-family: var(--font-serif);
  font-size: 1rem;
  line-height: 0.8;
  text-align: right;
}

.FooterPanel_title__ESVC2 > div:last-child {
  color: #0007;
}

.FooterPanel_links__6ECoO {
  width: 50%;
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  padding-left: 12px;
  gap: 6px;
  font-size: 18px;
}

.FooterPanel_links__6ECoO a {
  border: none;
  display: inline-flex;
  cursor: pointer;
  padding: 0.5em 0.5em;
  font-size: 1rem;
}

.FooterPanel_links__6ECoO > div.FooterPanel_section__baVr3 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 2em;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid var(--color-border);
  box-shadow: 0 0 inset var(--color-text);
  transition: 0.5s box-shadow;
}

.FooterPanel_links__6ECoO > div.FooterPanel_section__baVr3:hover {
  box-shadow: 0 -4px inset var(--color-text);
  border-bottom: 1px solid var(--color-text);
}

.FooterPanel_links__6ECoO > div.FooterPanel_section__baVr3:first-child {
  border-top: none;
}

.FooterPanel_links__6ECoO > div.FooterPanel_section__baVr3 > a {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
}

.FooterPanel_links__6ECoO > div.FooterPanel_section__baVr3 > button {
  cursor: pointer;
  border-radius: 999vmax;
  padding: 0 0.3em;
}

.FooterPanel_links__6ECoO > * {
  flex: 1 1 auto;
}

.FooterPanel_subscribe__6PCIU {
  display: grid;
  place-items: center;
  border: 1px solid var(--color-border);
  padding: 1em;
}

.FooterPanel_subscribe__6PCIU > div {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1em;
}

.FooterPanel_subscribe__6PCIU > div > div {
  font-family: var(--font-serif);
  text-wrap: balance;
}

.FooterPanel_subscribe__6PCIU > div > input {
  grid-column: 1/2;
  line-height: 2em;
  border: none;
  border-bottom: 1px solid var(--color-text);
}

.FooterPanel_subscribe__6PCIU > div > button {
  cursor: pointer;
  display: grid;
  place-items: center;
  height: 2em;
  border: 1px solid var(--color-border);
  padding: 0 1em;
}

@media (max-width: 840px) {
  .FooterPanel_footerPanel__0fm8A {
    grid-template-columns: minmax(300px, 400px);
  }

  .FooterPanel_title__ESVC2 {
    grid-column: 1/-1;
  }
}

@media (max-width: 700px) {
  .FooterPanel_footerPanel__0fm8A {
    grid-template-columns: 1fr;
  }

  .FooterPanel_title__ESVC2 {
    grid-column: 1/-1;
  }

  .FooterPanel_links__6ECoO > div > a > span {
    display: none;
  }
}

/* For now, Goerges does not want us to display the social media names */
.FooterPanel_links__6ECoO > div > a > span {
  display: none;
}

button.PopupMenu_popupMenu__Y9z1C {
  cursor: pointer;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 1em;
  display: grid;
  place-items: center;
  z-index: 9999;
  pointer-events: none;
  opacity: 0;
  background-color: #fff7;
  transition: all 0.2s;
}

button.PopupMenu_popupMenu__Y9z1C > menu {
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0;
  background-color: #fff;
  padding: 1em;
  border: 1px solid var(--color-border);
}

button.PopupMenu_popupMenu__Y9z1C > menu > header {
  display: flex;
  justify-content: flex-end;
}

button.PopupMenu_popupMenu__Y9z1C > menu > header > button.PopupMenu_close__2jFdK {
  font-size: 1rem;
}

button.PopupMenu_popupMenu__Y9z1C > menu > section {
  margin: 2em 0;
}

button.PopupMenu_popupMenu__Y9z1C.PopupMenu_show__BrSdc {
  opacity: 1;
  pointer-events: all;
}

button.PopupMenu_popupMenu__Y9z1C.PopupMenu_show__BrSdc section > button {
  cursor: pointer;
  padding: 0.2em 0.5em;
  margin: 0;
  margin-top: -1px;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  font-family: var(--font-serif);
  font-size: 1.5rem;
  width: 100%;
}

@media (min-width: 801px) {
  button.PopupMenu_popupMenu__Y9z1C.PopupMenu_show__BrSdc {
    opacity: 0;
    pointer-events: none;
  }
}

a.HeaderLoginButton_headerLoginButton__GvF4x,
a.HeaderLoginButton_headerLoginButton__GvF4x:hover,
a.HeaderLoginButton_headerLoginButton__GvF4x:active {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding: 0;
}

.HeaderLoginButton_headerLoginButton__GvF4x span {
  display: inline-block;
  max-width: 6em;
}

.HeaderLoginButton_headerLoginButton__GvF4x .HeaderLoginButton_large__Ghwox {
  display: inline-block;
}

.HeaderLoginButton_headerLoginButton__GvF4x .HeaderLoginButton_small__QdA67 {
  display: none;
}

.HeaderLoginButton_headerLoginButton__GvF4x.HeaderLoginButton_stuck__fRmyd .HeaderLoginButton_large__Ghwox {
  display: none;
}

.HeaderLoginButton_headerLoginButton__GvF4x.HeaderLoginButton_stuck__fRmyd .HeaderLoginButton_small__QdA67 {
  display: inline-block;
}

.HeaderLoginButton_headerLoginButton__GvF4x .HeaderLoginButton_arrow__8AWrM {
  align-self: flex-end;
}

.HeaderLoginButton_headerLoginButton__GvF4x.HeaderLoginButton_stuck__fRmyd .HeaderLoginButton_arrow__8AWrM {
  display: none;
}

.Menu_menuContainer__WNCoW {
  --size-button: 160px;
  --size-header: var(--size-button);
}

.Menu_menuContainer__WNCoW .Menu_menu__cSo_V.Menu_stuck__7umrV {
  --size-header: calc(0.5 * var(--size-button));
}

.Menu_menu__cSo_V {
  font-size: var(--font-size-M);
  position: sticky;
  top: calc(-1px - 1em);
  z-index: 9;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0;
  background-color: #fff;
  height: var(--size-button);
  border: none;
  overflow: hidden;
}

@media (max-width: 1200px) {
  .Menu_menuContainer__WNCoW {
    --size-button: 120px;
  }
}

@media (max-width: 1000px) {
  .Menu_menuContainer__WNCoW {
    --size-button: 100px;
  }
}

@media (max-width: 800px) {
  .Menu_menuContainer__WNCoW {
    --size-button: 80px;
  }
}

.Menu_menu__cSo_V.Menu_stuck__7umrV {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: var(--size-header);
  max-height: var(--size-header);
  overflow: hidden;
  z-index: 999;
  pointer-events: all;
}

.Menu_menuContainer__WNCoW .Menu_menu__cSo_V > button {
  height: var(--size-header);
  font-size: var(--font-size-M);
}

.Menu_menu__cSo_V > button.Menu_logo__KBgtW {
  background-color: var(--color-hero-background);
  font-size: calc(0.25 * var(--size-button));
  padding: 0.75rem 1rem;
  line-height: 0.8;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  cursor: pointer;
  font-family: var(--font-serif);
  width: 100%;
  transition: none;
}

.Menu_menu__cSo_V > button.Menu_logo__KBgtW.Menu_selected__coAtk {
  background: var(--color-text);
  color: #fffe;
  border: 1px solid var(--color-text);
}

.Menu_menu__cSo_V.Menu_stuck__7umrV > button.Menu_logo__KBgtW {
  font-size: calc(0.45 * var(--size-header));
  align-items: center;
  padding: 0 0.5em;
}

.Menu_menu__cSo_V.Menu_stuck__7umrV > button.Menu_logo__KBgtW > * {
  margin: 0;
}

.Menu_menu__cSo_V > button.Menu_logo__KBgtW > div {
  text-align: right;
  max-width: 7em;
}

.Menu_menu__cSo_V > div.Menu_items__deqlh {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.Menu_menu__cSo_V button {
  border: 1px solid var(--color-border);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem;
  margin: 0 0 0 -1px;
  color: var(--color-text);
  background-color: #fff;
  cursor: pointer;
  transition: all 0.2s;
  width: var(--size-button);
  height: var(--size-button);
  box-sizing: border-box;
  box-shadow: 0 0 currentColor inset;
}

.Menu_menu__cSo_V.Menu_stuck__7umrV div.Menu_hamburger__Yvp3A > button {
  flex-direction: row;
  align-items: center;
}

.Menu_menu__cSo_V.Menu_stuck__7umrV button {
  height: var(--size-header);
  line-height: 1;
}

.Menu_menu__cSo_V.Menu_stuck__7umrV button > svg {
  font-size: 0;
}

.Menu_menu__cSo_V button.Menu_selected__coAtk {
  color: #fff;
  background-color: var(--color-text);
  pointer-events: none;
  cursor: default;
  border: 1px solid var(--color-text);
}

.Menu_menu__cSo_V > div > button:hover {
  box-shadow: 0 -0.5rem currentColor inset;
  padding-bottom: 1rem;
}

.Menu_menu__cSo_V > div > button.Menu_loginButton__N0R3E:hover {
  box-shadow: 0 -0.5rem #fff7 inset;
  color: #fffe;
  background-color: var(--color-text);
  transition: all 0.6s;
}

.Menu_menu__cSo_V > div > button > svg {
  place-self: end;
}

.Menu_menu__cSo_V > div.Menu_hamburger__Yvp3A {
  display: none;
}

@media (max-width: 480px) {
  .Menu_menu__cSo_V > button.Menu_logo__KBgtW {
    font-size: 2rem;
    padding: 12px;
  }

  .Menu_menu__cSo_V.Menu_stuck__7umrV > button.Menu_logo__KBgtW {
    font-size: 1.5rem;
    padding: 0.5em;
  }

  .Menu_menu__cSo_V.Menu_stuck__7umrV .Menu_hamburger__Yvp3A > button {
    display: grid;
    place-items: center;
    width: 4em;
  }

  .Menu_menu__cSo_V.Menu_stuck__7umrV .Menu_hamburger__Yvp3A > button > svg {
    font-size: calc(0.5 * var(--size-header));
    place-self: center;
  }

  .Menu_menu__cSo_V.Menu_stuck__7umrV .Menu_hamburger__Yvp3A > button > div {
    display: none;
  }
}

@media (max-width: 800px) {
  .Menu_menu__cSo_V > div.Menu_items__deqlh {
    display: none;
  }
  .Menu_menu__cSo_V > div.Menu_hamburger__Yvp3A {
    display: block;
  }
}

.HorizontalRuler_verticalRuler__sq5ip {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  margin: 1em 0;
}

.HorizontalRuler_verticalRuler__sq5ip > div {
  display: block;
  height: 2px;
  width: 100%;
  font-size: 0;
  content: '';
  background-color: currentColor;
}

.Milestones_milestones__pBXiB .Milestones_step__L0DMv {
  display: grid;
  --size: 256px;
  grid-template-columns: var(--margin) var(--size) 1fr;
  gap: 24px;
  max-height: var(--size);
  padding: 0;
  margin: 0;
}

.Milestones_step__L0DMv img {
  height: var(--size);
  grid-column: 2/3;
}

.Milestones_step__L0DMv > div {
  max-width: 640px;
  display: grid;
  grid-template-rows: auto 1fr auto;
  place-items: start;
}

.Milestones_step__L0DMv > div > h2 {
  font-family: var(--font-serif);
  font-size: var(--Sizes-Font-7xl, 60px);
}

.Milestones_milestones__pBXiB > header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  flex-wrap: nowrap;
  margin-bottom: 2em;
}

.Milestones_milestones__pBXiB > header > button {
  margin: 0;
  position: 0;
  cursor: pointer;
}

.Milestones_milestones__pBXiB > header > button[disabled] {
  opacity: 0.5;
  filter: grayscale(1);
}

.Milestones_milestones__pBXiB > header > button > svg {
  flex: 0 0 auto;
}

.Milestones_milestones__pBXiB > header > div {
  flex: 1 1 auto;
}

.SectionInstitute_sectionInstitute__v_yon p {
}

.VerticalSpace_verticalSpace__p2WAh {
}

button.MissionStatement_missionStatement__ndodh {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  gap: 0;
  width: 800px;
  width: calc(100vw - max(8px, var(--margin, 0)));
  cursor: pointer;
  border-radius: 16px;
  box-shadow: 0 16px 32px #0005;
  background-color: var(--color-hero-background);
  overflow: hidden;
  margin: 5rem 0;
}

.MissionStatement_missionStatement__ndodh > div.MissionStatement_text__VRchR {
  display: flex;
  flex-direction: column;
  gap: 0;
  justify-content: flex-start;
  align-items: stretch;
  padding: 24px;
}

.MissionStatement_missionStatement__ndodh > div.MissionStatement_text__VRchR > * {
  flex: 0 0 auto;
}

.MissionStatement_missionStatement__ndodh > div.MissionStatement_text__VRchR > h2 {
  font-family: var(--font-serif);
  font-size: var(--font-size-XL);
  flex: 1 1 auto;
  margin: 0;
  line-height: 0.8;
  margin-bottom: 0.5em;
}

.MissionStatement_missionStatement__ndodh > div.MissionStatement_image__zw9W5 {
  background-color: var(--color-text);
  background-image: url(/app/_next/static/media/image.4c5de774.jpg);
  background-size: cover;
  display: grid;
  place-items: center;
  color: #fff;
  font-size: 48px;
  min-width: 240px;
  min-height: 240px;
}

@media (max-width: 800px) {
  button.MissionStatement_missionStatement__ndodh {
    flex-direction: column;
  }
}

.SectionOurMission_sectionOurMission__6z6cr ul {
  margin: 0;
  margin-left: var(--margin);
  padding: 0;
  list-style: none;
  --indentation: 64px;
}

@media (max-width: 640px) {
  .SectionOurMission_sectionOurMission__6z6cr ul {
    --indentation: 48px;
  }
}

@media (max-width: 480px) {
  .SectionOurMission_sectionOurMission__6z6cr ul {
    --indentation: 32px;
  }
}

.SectionOurMission_sectionOurMission__6z6cr ul big {
  position: relative;
  font-family: var(--font-serif);
  font-size: 32px;
  margin: 0;
  padding: 0;
}

.SectionOurMission_sectionOurMission__6z6cr ul big::before {
  content: '';
  position: absolute;
  margin-left: calc(-1 * var(--indentation));
  left: 0;
  top: 0.75em;
  width: calc(0.8 * var(--indentation));
  height: 2px;
  background-color: currentColor;
}

.SectionOurMission_sectionOurMission__6z6cr ul p {
  padding: 0;
  margin: 0;
}

.SectionOurMission_sectionOurMission__6z6cr ul > li {
  margin-left: var(--indentation, 64px);
  margin-bottom: 2em;
}

@media (max-width: 640px) {
  .SectionOurMission_sectionOurMission__6z6cr ul big {
    font-size: 26px;
  }
}

@media (max-width: 480px) {
  .SectionOurMission_sectionOurMission__6z6cr ul big {
    font-size: 20px;
  }
}

a.EmailButton_emailButton__lsVQc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  border: 1px solid var(--color-border);
  padding: 48px;
  cursor: pointer;
  width: min(500px, calc(100vw - 8px));
  max-width: calc(100vw - 8px);
}

.EmailButton_emailButton__lsVQc > big {
  font-size: var(--font-size-L);
  font-family: var(--font-serif);
  margin: 0;
}

@media (max-width: 640px) {
  a.EmailButton_emailButton__lsVQc {
    padding: 16px 8px;
  }
}

.SectionPricing_sectionPricing__7ovRG {
}

.TeamMember_teamMember__gz__J {
  display: grid;
  grid-template-rows: 1fr auto auto;
  gap: 0;
  width: min(100vw, 300px);
  height: 240px;
}

.TeamMember_teamMember__gz__J.TeamMember_big__xptrZ {
  width: min(100vw, 320px);
  height: 360px;
}

.TeamMember_teamMember__gz__J > div.TeamMember_image__MD6GX {
  position: relative;
  content: '';
  background-size: cover;
  background-color: var(--color-text);
  background-image: url(/app/_next/static/media/background.bd275652.jpg);
}

.TeamMember_teamMember__gz__J > div.TeamMember_image__MD6GX > img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  opacity: 0;
  transition: opacity 1s;
}

.TeamMember_teamMember__gz__J > div.TeamMember_image__MD6GX > img.TeamMember_ready__PsgG_ {
  opacity: 1;
}

.TeamMember_teamMember__gz__J > div.TeamMember_name__u11SR {
  font-family: var(--font-serif);
  font-size: var(--font-size-L);
  background-color: var(--color-hero-background);
  padding: 0 1em;
  margin: 0;
  line-height: 1;
  display: grid;
  place-items: center;
  height: 3em;
}

.TeamMember_teamMember__gz__J.TeamMember_big__xptrZ > div.TeamMember_name__u11SR {
  font-size: var(--font-size-L);
}

.TeamMember_teamMember__gz__J > div.TeamMember_profile__Ow_ah {
  text-align: right;
  font-size: var(--font-size-M);
}

.SectionOurTeam_people__CHvt7 {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: stretch;
}

.SectionOurTeam_board___Vsn5 {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: stretch;
  margin-bottom: 3rem;
}

.SectionContact_emails__4ACii {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
  margin-left: var(--margin);
}

.TermsAndConditions_termsAndConditions__Pm_uL p {
  max-width: 1000px;
  margin-top: 1em;
  margin-bottom: 1em;
}

.TermsAndConditions_termsAndConditions__Pm_uL ol {
  margin-left: var(--margin);
}

.TermsAndConditions_termsAndConditions__Pm_uL ol > li {
  list-style: none;
  margin-top: 3em;
  max-width: 900px;
}

.TermsAndConditions_termsAndConditions__Pm_uL ol > li > h2 {
  font-size: 150%;
  font-weight: bolder;
  margin-top: -0.5em;
}

.TermsAndConditions_termsAndConditions__Pm_uL ol > li ul {
  margin-left: 1em;
}

.TermsAndConditions_termsAndConditions__Pm_uL ol > li ul > li {
  list-style: disc;
}

