.footerPanel {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.title {
  width: 50%;
  padding: 1rem;
  background-color: var(--color-hero-background);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.title > h2 {
  text-align: right;
  line-height: 0.8;
}

.title > div:first-child {
  font-family: var(--font-serif);
  font-size: 1rem;
  line-height: 0.8;
  text-align: right;
}

.title > div:last-child {
  color: #0007;
}

.links {
  width: 50%;
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  padding-left: 12px;
  gap: 6px;
  font-size: 18px;
}

.links a {
  border: none;
  display: inline-flex;
  cursor: pointer;
  padding: 0.5em 0.5em;
  font-size: 1rem;
}

.links > div.section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 2em;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid var(--color-border);
  box-shadow: 0 0 inset var(--color-text);
  transition: 0.5s box-shadow;
}

.links > div.section:hover {
  box-shadow: 0 -4px inset var(--color-text);
  border-bottom: 1px solid var(--color-text);
}

.links > div.section:first-child {
  border-top: none;
}

.links > div.section > a {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
}

.links > div.section > button {
  cursor: pointer;
  border-radius: 999vmax;
  padding: 0 0.3em;
}

.links > * {
  flex: 1 1 auto;
}

.subscribe {
  display: grid;
  place-items: center;
  border: 1px solid var(--color-border);
  padding: 1em;
}

.subscribe > div {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1em;
}

.subscribe > div > div {
  font-family: var(--font-serif);
  text-wrap: balance;
}

.subscribe > div > input {
  grid-column: 1/2;
  line-height: 2em;
  border: none;
  border-bottom: 1px solid var(--color-text);
}

.subscribe > div > button {
  cursor: pointer;
  display: grid;
  place-items: center;
  height: 2em;
  border: 1px solid var(--color-border);
  padding: 0 1em;
}

@media (max-width: 840px) {
  .footerPanel {
    grid-template-columns: minmax(300px, 400px);
  }

  .title {
    grid-column: 1/-1;
  }
}

@media (max-width: 700px) {
  .footerPanel {
    grid-template-columns: 1fr;
  }

  .title {
    grid-column: 1/-1;
  }

  .links > div > a > span {
    display: none;
  }
}

/* For now, Goerges does not want us to display the social media names */
.links > div > a > span {
  display: none;
}
