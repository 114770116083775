button.popupMenu {
  cursor: pointer;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 1em;
  display: grid;
  place-items: center;
  z-index: 9999;
  pointer-events: none;
  opacity: 0;
  background-color: #fff7;
  transition: all 0.2s;
}

button.popupMenu > menu {
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0;
  background-color: #fff;
  padding: 1em;
  border: 1px solid var(--color-border);
}

button.popupMenu > menu > header {
  display: flex;
  justify-content: flex-end;
}

button.popupMenu > menu > header > button.close {
  font-size: 1rem;
}

button.popupMenu > menu > section {
  margin: 2em 0;
}

button.popupMenu.show {
  opacity: 1;
  pointer-events: all;
}

button.popupMenu.show section > button {
  cursor: pointer;
  padding: 0.2em 0.5em;
  margin: 0;
  margin-top: -1px;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  font-family: var(--font-serif);
  font-size: 1.5rem;
  width: 100%;
}

@media (min-width: 801px) {
  button.popupMenu.show {
    opacity: 0;
    pointer-events: none;
  }
}
