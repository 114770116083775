.verticalRuler {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  margin: 1em 0;
}

.verticalRuler > div {
  display: block;
  height: 2px;
  width: 100%;
  font-size: 0;
  content: '';
  background-color: currentColor;
}
