a.card {
  position: relative;
  border: 1px solid var(--color-border);
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 800px;
  height: 200px;
  max-width: calc(100vw - 8px);
  margin: 0;
  padding: 0;
  transition: all 0.6s;
}

a.card .content h2 {
  margin: 0;
}

a.card:hover {
  color: #fffe;
  background-color: var(--color-text);
}

a.card .content {
  width: 100%;
  transition: all 0.6s;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-image: linear-gradient(35deg, #fff, #fffd 60%, #fff0);
  z-index: 1;
}

a.card:hover .content {
  background: var(--color-text);
}

.content h2 {
  font-family: var(--font-serif);
}

a.card .pictureContainer {
  position: relative;
  flex: 0 0 auto;
  width: 200px;
  height: 100%;
  overflow: hidden;
}

a.card .picture {
  object-fit: cover;
  z-index: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.6s;
}

a.card:hover .picture {
  transform: scale(1.2) rotate(10deg);
}

@media (max-width: 800px) {
  a.card .pictureContainer {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  a.card {
    height: 280px;
  }

  a.card .content {
    color: #fffe;
    background: color-mix(in srgb, var(--color-text), transparent);
  }
}
