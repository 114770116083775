.hero {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0;

  --color-bg: #f0f0f0;
  --gradient-angle: 150deg;
}

.splash.image {
  --color-bg: var(--color-text);
}

.splash {
  height: calc(75vh - 100px);
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 0;
  margin: 0;
}

.splash > div:last-child {
  position: relative;
  border: 1px solid var(--color-border);
  border-top: none;
  background: var(--color-text);
}

.splash > div:last-child > video,
.splash > div:last-child > img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top right;
  z-index: 0;
}

.splash .text {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(
    var(--gradient-angle),
    var(--color-bg) 29.85%,
    rgba(240, 240, 240, 0) 61.87%
  );
  z-index: 1;
  font-size: clamp(2rem, 5vw, 3rem);
  padding-left: 2em;
  text-wrap: balance;
}

.splash.image .text {
  color: #fff;
  background: linear-gradient(
    var(--gradient-angle),
    var(--color-bg) 29.85%,
    rgba(240, 240, 240, 0) 61.87%
  );
}

.splash > div:last-child > div > * {
  /* max-width: 600px; */
  max-width: 80%;
}

.splash > div:last-child > div > div.content {
  font-size: 1.5rem;
}

h1.largeTitle {
  font-family: var(--font-serif);
  font-size: 4vw;
  line-height: 1.25;
  margin: 0;
  padding: 0;
}

h1.largeTitle::after {
  display: none;
}

@media (max-width: 800px) {
  .splash {
    grid-template-columns: 0px 1fr;
  }

  h1.largeTitle {
    font-size: 7.5vw;
    text-shadow:
      0 0 0.5em var(--color-bg),
      0 0 0.125em var(--color-bg);
  }

  .splash > div:last-child > div > div.content {
    font-size: 1.2rem;
  }
}

@media (min-width: 640px) {
  .showOnlyForMobile {
    display: none;
  }
}
