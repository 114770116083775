.sectionOurMission ul {
  margin: 0;
  margin-left: var(--margin);
  padding: 0;
  list-style: none;
  --indentation: 64px;
}

@media (max-width: 640px) {
  .sectionOurMission ul {
    --indentation: 48px;
  }
}

@media (max-width: 480px) {
  .sectionOurMission ul {
    --indentation: 32px;
  }
}

.sectionOurMission ul big {
  position: relative;
  font-family: var(--font-serif);
  font-size: 32px;
  margin: 0;
  padding: 0;
}

.sectionOurMission ul big::before {
  content: '';
  position: absolute;
  margin-left: calc(-1 * var(--indentation));
  left: 0;
  top: 0.75em;
  width: calc(0.8 * var(--indentation));
  height: 2px;
  background-color: currentColor;
}

.sectionOurMission ul p {
  padding: 0;
  margin: 0;
}

.sectionOurMission ul > li {
  margin-left: var(--indentation, 64px);
  margin-bottom: 2em;
}

@media (max-width: 640px) {
  .sectionOurMission ul big {
    font-size: 26px;
  }
}

@media (max-width: 480px) {
  .sectionOurMission ul big {
    font-size: 20px;
  }
}
