.news > main {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 48px;
  flex-wrap: nowrap;
  scroll-behavior: smooth;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  padding-bottom: 24px;
}

.news > header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.news > header > * {
  flex: 1 1 auto;
}

.news > header > div {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px;
}

.news > header > div > button.page {
  content: '';
  width: 24px;
  height: 6px;
  border-radius: 99vmax;
  background-color: #0004;
}

.news > header > div > button.page.selected {
  background-color: var(--color-text);
}

.news > header > div > button.arrow {
  width: 3em;
  display: grid;
  place-items: center;
}
