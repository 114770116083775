button.missionStatement {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  gap: 0;
  width: 800px;
  width: calc(100vw - max(8px, var(--margin, 0)));
  cursor: pointer;
  border-radius: 16px;
  box-shadow: 0 16px 32px #0005;
  background-color: var(--color-hero-background);
  overflow: hidden;
  margin: 5rem 0;
}

.missionStatement > div.text {
  display: flex;
  flex-direction: column;
  gap: 0;
  justify-content: flex-start;
  align-items: stretch;
  padding: 24px;
}

.missionStatement > div.text > * {
  flex: 0 0 auto;
}

.missionStatement > div.text > h2 {
  font-family: var(--font-serif);
  font-size: var(--font-size-XL);
  flex: 1 1 auto;
  margin: 0;
  line-height: 0.8;
  margin-bottom: 0.5em;
}

.missionStatement > div.image {
  background-color: var(--color-text);
  background-image: url(./image.jpg);
  background-size: cover;
  display: grid;
  place-items: center;
  color: #fff;
  font-size: 48px;
  min-width: 240px;
  min-height: 240px;
}

@media (max-width: 800px) {
  button.missionStatement {
    flex-direction: column;
  }
}
